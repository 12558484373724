<template>
  <div class="card card-style">
    <div class="content">
      <div class="d-flex">
        <div class="align-self-center">
<!--          <img-->
<!--            @error="imgError = true"-->
<!--            :src="photoUser"-->
<!--            class="rounded-s me-3"-->
<!--            style="height: 50px; width: 50px; object-fit: cover"-->
<!--          />-->
          <div
            class="card bg-transparent me-3"
          >
            <img
              @error="imgError = true"
              :src="photoUser"
              class="card-img rounded-s preload-img shadow-xl img-fluid entered loaded"
              alt=""
              style="height: 84px; width: 84px; object-fit: cover"
            />
            <div
              class="card-img-overlay p-0 d-flex align-items-end"
              v-if="user.status_badge === 1"
            >
              <div
                class="w-100 text-center opacity-90"
                style="background-color: #663ba0 !important; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
              >
                <span class="fw-bold text-white" style="font-size: x-small">#OPENTOWORK</span>
              </div>
            </div>
          </div>
        </div>
        <div class="me-auto">
          <h2 class="font-15 color-highlight line-height-s mt-1 mb-n1">
            {{ user.first_name }} {{ user.last_name }}
          </h2>
          <p
            v-if="user.introduction_letter"
            class="mb-0 font-11 mt-2 line-height-s"
          ></p>
          <p class="mb-0 font-13">
            <span>{{ user.current_job_title }}</span>
          </p>
          <p v-if="user.year_experience" class="mb-0 font-13 line-height-s">
            <span v-if="user.year_experience !== 0"
              >pengalaman {{ user.year_experience }} tahun</span
            >
          </p>
        </div>
        <router-link
          :to="{ name: 'UserDetail', params: { id: user.id } }"
          href="#"
          class="
            btn btn-s
            font-600
            mt-auto
            mb-auto
            rounded-s
            gradient-highlight
            float-end
          "
          >Lihat</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "User",
  props: {
    user: Object,
  },
  data() {
    return {
      photoUrl: axios.defaults.baseURL + this.user.get_photo,
      imgError: false,
    };
  },
  watch: {
    photoUrl() {
      console.log(this.photoUrl);
    },
  },
  computed: {
    photoUser() {
      return this.imgError
        ? "/images/defaults/default_image.png"
        : this.photoUrl;
    },
  },
  mounted() {},
  methods: {},
};
</script>
