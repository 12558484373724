<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :add_class="['header-auto-show']" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
      ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 mx-auto">Cari Kandidat</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <div class="page-content">
      <!-- Banner -->
      <div class="" data-card-height="250">
        <div class="text-center">
          <div class="content">
            <div
              class="search-box search-dark shadow-xl  bg-theme mt-1"
            >
              <i class="fa fa-search"></i>
              <input
                type="text"
                class="border-0"
                placeholder="Cari Kandidat"
                v-model="dataSearch"
                @keyup.enter="this.filter"
              />
            </div>
            <div v-if="userList.length === 0" class="mt-5">
              <EmptyState
                :message1="'Temukan kandidat anda dengan menggunakan fitur pencarian'"
                :cardCenter="false"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End Banner -->

      <User v-for="user in userList" :key="user.id" :user="user" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";
import User from "@/components/employer/User";

export default {
  name: "UserList",
  components: {
    Header,
    Footer,
    EmptyState,
    User,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      dataSearch: "",
      resetFilter: false,
      dataFilterList: {},
      userList: [],
      apiUrl: "/api/v1/job-seeker/user-list/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
      next: "",
      reqData: false,
    };
  },
  watch: {},
  mounted() {
    let searchQuery = this.$store.state.userSearchQuery;
    if (searchQuery) {
      console.log("searchQuery : ", searchQuery);
      this.filter(event, searchQuery);
    }
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    this.getRecomended()
  },
  methods: {
    menuClose,
    menuOpen,
    togleReset() {
      this.resetFilter = true;
    },
    openFilter(data) {
      this.menuOpen(data);
      this.resetFilter = false;
    },
    getMoreUser() {
      window.onscroll = () => {
        var bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 2;
        if (bottomOfWindow && this.next) {
          console.log("get more user");
          this.getUserList();
        }
      };
    },
    async getUserList(urlInput) {
      var url = urlInput;
      this.next ? (url = this.next) : "";
      if (!this.reqData) {
        await axios
          .get(url)
          .then((response) => {
            console.log("response : ", response);
            this.next = response.data.next;
            response.data.results.forEach((item) => {
              if (!this.userList.includes(item)) {
                this.userList.push(item);
              }
            });
            this.isLoading = false;
            this.reqData = false;
          })
          .catch((error) => {
            this.reqData = false;
            console.log(error);
          });
      }
    },
    async getRecomended() {
      await axios
        .get('/user/list-recomended-user', {
          baseURL: this.apiEndpoint,
        })
        .then((response) => {
          response.data.data.forEach((item) => {
            if (!this.userList.includes(item)) {
              this.userList.push(item);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filter(event, searchQuery) {
      this.userList = [];
      let search = searchQuery ? searchQuery : "";
      if (this.dataSearch || searchQuery) {
        if (!searchQuery) {
          search = this.apiUrl + "?search=" + this.dataSearch;
        }
        this.$store.commit("setFindUserQuery", search);
        this.next = "";
        this.getUserList(search);
      }
    },
  },
};
</script>
